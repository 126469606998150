import React, { ReactNode } from 'react';
import { Flex, Props as FlexProps } from '../Flex';
import { Typography, Props as TypographyProps } from '../Typography';
import { StyledSystemProps } from '../../types';
import { Icon } from '../../wag-react/components/icon';
type IconProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
} & StyledSystemProps & Partial<Omit<JSX.IntrinsicElements['img'], 'height' | 'width'>>;
export type IconAndTextProps = {
  iconProps?: IconProps;
  iconSrc: string;
  text: ReactNode;
  textProps?: Omit<TypographyProps, 'children'>;
} & FlexProps;
export const IconAndText = ({
  iconSrc,
  iconProps,
  text,
  textProps,
  ...rest
}: IconAndTextProps) => <Flex alignItems="center" {...rest} data-sentry-element="Flex" data-sentry-component="IconAndText" data-sentry-source-file="index.tsx">
    <Icon height="24px" src={iconSrc} width="24px" alt={String(text)} {...iconProps} data-sentry-element="Icon" data-sentry-source-file="index.tsx" />
    <Typography fontWeight={3} m={0} ml="8px" variant="legal" {...textProps} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
      {text}
    </Typography>
  </Flex>;