import queryString from 'query-string';

import { ParserFunction } from '../type';

/**
 * Returns a single string comprised of an object's key/value pairings
 * Ex: { width: 40, height: 40 } -> 'width=40,height=40'
 */
const stringifyOptions = (options) => Object.entries(options)
  .reduce((accumulator, currentValue) => {
    const [key, value] = currentValue;
    accumulator.push(`${key}=${value}`);
    return accumulator;
  }, [])
  .join(',');

export const cloudflareUrlParser: ParserFunction = ({
  imageUrl,
  options = {},
  prefix = 'cdn-cgi/image',
}) => {
  if (!imageUrl) {
    return '';
  }
  if (!Object.keys(options).length) {
    // if no options, move on
    return imageUrl;
  }

  const {
    origin,
    pathname,
  } = new URL(imageUrl);

  // Conditional logic so that the prefix doesn't appear in the
  // to the return string more than once if the pathname already includes the prefix
  if (pathname.includes(prefix)) {
    let pathnameOptions = {};
    const newOptions = options;
    const splitPathname = pathname.split('/');
    const filename = splitPathname.slice(-1); // Get last element of array

    splitPathname.forEach((urlPart) => {
      let newUrlPart = urlPart;
      if (urlPart.includes('=')) {
        if (urlPart.includes(',')) {
          newUrlPart = urlPart.split(',').join('&');
        }
        pathnameOptions = queryString.parse(newUrlPart);
      }
    });

    Object.entries(pathnameOptions).forEach((option) => {
      const [key, value] = option;
      if (!newOptions[key]) {
        newOptions[key] = value;
      }
    });

    const stringifiedOptions = stringifyOptions(newOptions);
    const filenameWithOptions = `${stringifiedOptions}/${filename}`;
    return [origin, prefix, filenameWithOptions].join('/');
  }

  // comma separated options
  // https://developers.cloudflare.com/images/url-format
  const stringifiedOptions = stringifyOptions(options);
  const pathnameWithOptions = stringifiedOptions + pathname;
  return [origin, prefix, pathnameWithOptions]
    .join('/');
};
