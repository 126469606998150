import React, { useMemo } from 'react';
import { format } from 'date-fns';
import { Profile } from '../..';
import { DateFormats } from '../../../../../../constants';
import { Typography, TypographyProps } from '../../../../../../wag-react/components/Typography';
import { theme } from '../../../../../../wag-react/themes/base-theme';
import { Box } from '../../../../../../wag-react/next-components/box';
export type LastServiceCompletedBlockProps = Omit<TypographyProps, 'children'> & {
  value: Profile['lastServiceCompletedAt'];
};
export const LastServiceCompletedBlock = ({
  value,
  ...rest
}: LastServiceCompletedBlockProps) => {
  const lastServiceCompletedAtFormatted = useMemo(() => format(new Date(value), DateFormats.MonthYear), [value]);
  return <Typography display="flex" gridGap="4px" fontSize="14px" textAlign="right" color={theme.colors.darkGray} {...rest} data-sentry-element="Typography" data-sentry-component="LastServiceCompletedBlock" data-sentry-source-file="index.tsx">
      Last service:
      <Box as="time" dateTime={value} fontFamily="muliBold" fontSize="inherit" color={theme.colors.dimGray} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        {lastServiceCompletedAtFormatted}
      </Box>
    </Typography>;
};