import React from 'react';
import { ResponsiveValue, ThemeValue, RequiredTheme, LayoutProps } from 'styled-system';
import { Icon } from '../../wag-react/components/icon';
import { Flex, Props as FlexProps } from '../Flex';
import filledStarIcon from './assets/rating-star-filled.svg';
import unFilledStarIcon from './assets/rating-star-unfilled.svg';
export type Props = {
  value: number;
  starSpacing?: ResponsiveValue<ThemeValue<'space', RequiredTheme>, RequiredTheme>;
  starCount?: number;
  starIconProps?: LayoutProps;
} & FlexProps;
export const RatingStars = ({
  starSpacing = 0,
  starCount = 5,
  starIconProps,
  value,
  ...rest
}: Props) => {
  const stars = typeof starCount === 'number' && Array.from(Array(Math.ceil(starCount)).keys()) || [];
  return <Flex {...rest} data-sentry-element="Flex" data-sentry-component="RatingStars" data-sentry-source-file="index.tsx">
      {stars.map(index => <Icon key={index} height="16px" ml={index > 0 ? starSpacing : 0} src={index >= Math.round(value) ? unFilledStarIcon : filledStarIcon} width="16px" alt="rating star" {...starIconProps} />)}
    </Flex>;
};