'use client';

import React, { useState } from 'react';
import ReactImageCarousel, { Modal, ModalGateway } from 'react-images';
import { IframeVideoLightBoxView } from '../IframeVideoLightBoxView';
import { Container } from '../Container';
import { LazyImage, LazyImageProps } from '../LazyImage';
import { theme } from '../../theme/theme';
import { PlayIcon } from '../../icons/PlayIcon';
import { Box, BoxProps } from '../../wag-react/next-components/box';
import { Typography } from '../../wag-react/components/Typography';
import { Flex } from '../../wag-react/next-components/Flex';
import { Button } from '../../wag-react/next-components/button';
export type ServiceVideoSectionProps = BoxProps & {
  title?: string;
  titleProps?: BoxProps;
  thumbnail?: Pick<LazyImageProps, 'src'>['src'];
  thumbnailProps?: Omit<LazyImageProps, 'src'>;
  thumbnailContainerProps?: BoxProps;
  src?: string;
  contentProps?: BoxProps;
  containerProps?: BoxProps;
};
export const ServiceVideoSection = ({
  title,
  titleProps,
  thumbnail,
  thumbnailProps,
  thumbnailContainerProps,
  src,
  contentProps,
  containerProps,
  ...rest
}: ServiceVideoSectionProps) => {
  const [isVideoShowing, setIsVideoShowing] = useState(false);
  return <Box backgroundColor={theme.colors.backgroundGray} {...rest} data-sentry-element="Box" data-sentry-component="ServiceVideoSection" data-sentry-source-file="index.tsx">
      <Container py={['40px']} {...containerProps} data-sentry-element="Container" data-sentry-source-file="index.tsx">
        {title && <Typography fontSize={['24px', '30px', '48px']} fontWeight="bold" textAlign="center" maxWidth={['308px', '504px', '100%']} mt={0} mx="auto" mb={['20px', '40px']} {...titleProps}>
            {title}
          </Typography>}

        <Box height={['280px', '520px']} width={[1, null, '769px']} py="16px" mx="auto" backgroundRepeat="no-repeat" style={{
        backgroundPositionX: '16px',
        backgroundPositionY: '30px'
      }} {...contentProps} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <Box height={['200px', '440px']} width={1} borderRadius="6px" overflow="hidden" {...thumbnailContainerProps} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <LazyImage src={thumbnail} width={[1, null, '769px']} height={['200px', '440px']} {...thumbnailProps} imageProps={{
            onClick: () => setIsVideoShowing(true),
            width: '100%',
            height: '100%',
            ...thumbnailProps?.imageProps,
            style: {
              objectFit: 'cover',
              ...thumbnailProps?.imageProps?.style
            }
          }} data-sentry-element="LazyImage" data-sentry-source-file="index.tsx" />
          </Box>
          <Flex pl={['unset', '36px']} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <Button mt={['-36px', '-48px']} onClick={() => setIsVideoShowing(true)} zIndex={99} type="button" title="Play button" data-sentry-element="Button" data-sentry-source-file="index.tsx">
              <PlayIcon fontSize={['48px', '96px']} alt="Play button" data-sentry-element="PlayIcon" data-sentry-source-file="index.tsx" />
            </Button>
            <Flex height={['20px', '40px']} mx={['unset', '16px']} mt="8px" overflow="hidden" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
              <Button fontWeight="bold" fontSize={['16px', '20px']} color="wagGreen" title="Watch the video" m={0} onClick={() => setIsVideoShowing(true)} data-sentry-element="Button" data-sentry-source-file="index.tsx">
                Watch the video
              </Button>
            </Flex>
          </Flex>
        </Box>
        {/* @ts-expect-error - Modal error */}
        <ModalGateway data-sentry-element="ModalGateway" data-sentry-source-file="index.tsx">
          {isVideoShowing ? <Modal onClose={() => setIsVideoShowing(false)}>
              <ReactImageCarousel currentIndex={0} components={{
            Footer: null,
            // @ts-expect-error - IframeVideoLightBoxView error
            View: IframeVideoLightBoxView
          }} frameProps={{
            autoSize: 'height'
          }} views={[{
            source: src
          }]} />
            </Modal> : null}
        </ModalGateway>
      </Container>
    </Box>;
};