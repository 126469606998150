import React, { ReactNode } from 'react';
import { WAG_HELP_CENTER_URL } from '../../constants';
import { BoxProps } from '../../wag-react/next-components/box';
import { Accordion, AccordionProps } from '../Accordion';
import { ButtonLink } from '../ButtonLink';
import { Container, ContainerProps } from '../Container';
import { SectionHeading, SectionHeadingProps } from '../SectionHeading';
import { Typography, TypographyProps } from '../Typography';
const defaultFooterText = <>
    Didn&apos;t find the answer? Visit our&nbsp;
    <ButtonLink href={WAG_HELP_CENTER_URL} fontSize={['mobile-body1', 'tablet-body1', 'desktop-body1']} fontWeight="700">
      FAQ page
    </ButtonLink>
  </>;
type Props = Pick<AccordionProps, 'items' | 'useMarkdown'> & {
  footerText?: ReactNode;
  footerTextProps?: Omit<TypographyProps, 'children'>;
  headingText?: ReactNode;
  headingProps?: Omit<SectionHeadingProps, 'children'>;
  secondaryFooterText?: ReactNode;
  secondaryFooterTextProps?: Omit<SectionHeadingProps, 'children'>;
  accordionTitleProps?: Omit<TypographyProps, 'children'>;
  accordionProps?: BoxProps;
} & Omit<ContainerProps, 'children'> & {
  children?: ContainerProps['children'];
};
export type FaqsSectionProps = Props;
export const FaqsSection = ({
  footerText = defaultFooterText,
  footerTextProps,
  headingText = 'Frequently asked questions',
  headingProps,
  secondaryFooterText,
  secondaryFooterTextProps,
  items,
  useMarkdown,
  children,
  accordionTitleProps,
  accordionProps,
  ...rest
}: Props) => <Container pb={['48px', '32px', '120px']} pt={['48px', '96px', '120px']} {...rest} data-sentry-element="Container" data-sentry-component="FaqsSection" data-sentry-source-file="FaqsSection.tsx">
    <SectionHeading {...headingProps} data-sentry-element="SectionHeading" data-sentry-source-file="FaqsSection.tsx">
      {headingText}
    </SectionHeading>
    <Accordion items={items} useMarkdown={useMarkdown} my="40px" accordionTitleProps={accordionTitleProps} {...accordionProps} data-sentry-element="Accordion" data-sentry-source-file="FaqsSection.tsx" />
    <Typography textAlign="center" {...footerTextProps} data-sentry-element="Typography" data-sentry-source-file="FaqsSection.tsx">
      {footerText}
    </Typography>
    {secondaryFooterText && <Typography color="medPurple" maxWidth={['316px', '588px', '700px']} mb={0} mt="24px" mx="auto" textAlign="center" variant="legal" {...secondaryFooterTextProps}>
        {secondaryFooterText}
      </Typography>}
    {children}
  </Container>;