'use client';

import React, { useState, ReactNode, ElementType } from 'react';
import styled from 'styled-components';
import { system } from 'styled-system';
import { Text } from '../../wag-react/core-components/text';
import { ButtonProps } from '../../wag-react/next-components/button';
import { Flex } from '../Flex';
import { TextButton } from '../TextButton';
import { Typography, TypographyProps } from '../Typography';
export type Props = {
  text: string;
  clampComponent?: ElementType;
  maxCharactersToDisplay?: number;
  lineClamp?: number[] | number;
  clampProps?: ButtonProps;
  renderClamp?: (options: {
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
  }) => ReactNode;
} & Omit<TypographyProps, 'children'>;
export const TruncatedTypography = ({
  clampComponent: ClampComponent = TextButton,
  maxCharactersToDisplay = 260,
  renderClamp,
  text,
  clampProps,
  ...rest
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isClampButtonVisible = text.length > maxCharactersToDisplay;
  return <>
      <StyledTruncatedTypography component={Text} lineClamp={[5, 4]} m={0} showFullText={isExpanded} variant="body2" {...rest} data-sentry-element="StyledTruncatedTypography" data-sentry-source-file="index.tsx">
        {text}
      </StyledTruncatedTypography>
      <Flex data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        {isClampButtonVisible && (renderClamp ? renderClamp({
        isExpanded,
        setIsExpanded
      }) : <ClampComponent mt="8px" onClick={e => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
      }} {...clampProps}>
              {isExpanded ? 'Show less' : 'Read more'}
            </ClampComponent>)}
      </Flex>
    </>;
};
const lineClamp = system({
  lineClamp: {
    property: 'WebkitLineClamp'
  }
});
type StyledTruncatedTypographyProps = Pick<Props, 'lineClamp'> & {
  showFullText: boolean;
};
const StyledTruncatedTypography = styled(Typography)<StyledTruncatedTypographyProps>`
  ${lineClamp}
  ${({
  showFullText
}) => !showFullText && `
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    `}
`;