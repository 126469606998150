import React from 'react';
import { LazyImage as WagLazyImage, LazyImageProps as WagLazyImageProps } from '../../wag-react/components/lazyload/LazyImage';
import { useGetResponsivePropValue } from '../../hooks/useGetResponsivePropValue';

// TODO - move this support to wag-react
export type LazyImageProps = Omit<WagLazyImageProps, 'src'> & {
  src: string | string[];
};
export const LazyImage = ({
  src,
  ...rest
}: LazyImageProps) => {
  const imageSrc = useGetResponsivePropValue(src);
  return <WagLazyImage src={imageSrc} {...rest} data-sentry-element="WagLazyImage" data-sentry-component="LazyImage" data-sentry-source-file="index.tsx" />;
};