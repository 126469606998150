import React, { SyntheticEvent } from 'react';
import { Box, Props as BoxProps } from '../../../Box';
import { ButtonLink } from '../../../ButtonLink';
import { Flex } from '../../../Flex';
import { IconAndText } from '../../../IconAndText';
import { Link } from '../../../Link';
import { RatingStars } from '../../../RatingStars';
import { TruncatedTypography } from '../../../TruncatedTypography';
import { Typography } from '../../../Typography';
import { ProfilesListItemSpecialties, ProfileField } from './components/ProfilesListItemSpecialties';
import { ProfileReview } from './components/ProfileReview';
import { LastServiceCompletedBlock } from './components/LastServiceCompletedBlock';
import { ReviewSchema } from '../../../../utils/validation-schemas/walkerProfile/review';
import { formatter } from '../../../../utils/formatter/formatter';
import { cloudflareUrlParser } from '../../../../utils/formatter/parsers/cloudflareUrlParser';
import avatarPlaceholder from './assets/avatar-placeholder.svg';
import { LazyImage } from '../../../../wag-react/components/lazyload/LazyImage';
import { Placeholder as LazyImagePlaceholder } from '../../../../wag-react/components/lazyload/Placeholder';
import { Span } from '../../../../wag-react/core-components/span';
import { Grid } from '../../../../wag-react/core-components/grid';
import { Divider } from '../../../../wag-react/core-components/divider';
const MAX_CHARACTERS_TO_DISPLAY_BIO = 500;
const SPECIALTIES_LABEL = 'Specialties';
type ProfileTag = {
  iconSrc: string;
  label: string;
};
type ProfileService = {
  iconSrc: string;
  isChecked: boolean;
  label: string;
  slug: string;
};
type ProfileBadge = {
  asset: string;
  category: string; // '1'
  count: number;
  description: string;
  id: number;
  label: string;
  weight: number;
};
export type Profile = {
  badges: ProfileBadge[];
  bio: string;
  catchphrase: string;
  fields: ProfileField[];
  isOptIn: boolean;
  link: string;
  name: string;
  picture: string;
  promoCode: string;
  rating: number;
  ratingCount: number;
  services: ProfileService[];
  servicesCount: number;
  tags: ProfileTag[];
  thumbnail: string;
  video: string | null;
  review?: ReviewSchema;
  location?: string;
  lastServiceCompletedAt?: string;
};
export type ProfilesListItemProps = {
  bookNowButtonLink?: string | ((args: {
    profile: Profile;
  }) => string);
  onClickBookNow?: (event: SyntheticEvent<HTMLButtonElement>, profile: Profile) => void;
  data: {
    profile: Profile;
  };
  location: string;
} & BoxProps;
const cloudflareUrlFormatter = formatter({
  parser: cloudflareUrlParser
});
export const ProfilesListItem = ({
  bookNowButtonLink,
  onClickBookNow,
  data: {
    profile
  },
  location,
  ...rest
}: ProfilesListItemProps) => {
  const {
    bio,
    catchphrase,
    fields,
    link,
    name,
    rating,
    ratingCount,
    tags,
    thumbnail,
    review,
    lastServiceCompletedAt
  } = profile;

  // fields is an array of objects, look for the one that contains "Specialties"
  const specialties = fields.find(field => field.title === SPECIALTIES_LABEL);
  const bookNowButtonHref = bookNowButtonLink && (typeof bookNowButtonLink === 'string' ? bookNowButtonLink : bookNowButtonLink({
    profile
  })) || undefined;

  // TODO - Think how we can create an Image component that uses next/images (future),
  // our LazyImage, and our Image
  const resizedThumbnail = cloudflareUrlFormatter({
    imageUrl: thumbnail,
    options: {
      width: 60,
      height: 60
    }
  });
  return <Box {...rest} data-sentry-element="Box" data-sentry-component="ProfilesListItem" data-sentry-source-file="index.tsx">
      <Flex alignItems={['flex-start', 'center']} bg="#F5F5F5" borderRadius="100px 8px 0 100px" justifyContent="space-between" pl={['8px', '16px']} pr={['8px', '24px']} py="12px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Link href={link} target="_blank" data-sentry-element="Link" data-sentry-source-file="index.tsx">
          <Flex data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <LazyImage src={resizedThumbnail || avatarPlaceholder} alt={name} height={['48px', '60px']} width={['48px', '60px']} bg="none" overflow="unset" imageProps={{
            width: ['48px', '60px'],
            borderRadius: '50%'
          }} placeholder={<LazyImagePlaceholder width={['48px', '60px']} borderRadius="50%" />} data-sentry-element="LazyImage" data-sentry-source-file="index.tsx" />
            <Flex flexDirection="column" ml={['16px', '24px']} width={1} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
              <Typography hover component={Span} fontWeight={5} m={0} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
                {name}
              </Typography>
              <Typography m={0} variant="body2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
                {location || 'Location not specified'}
              </Typography>
              <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                <RatingStars starIconProps={{
                height: '14px',
                width: '14px'
              }} starSpacing="4px" value={rating} data-sentry-element="RatingStars" data-sentry-source-file="index.tsx" />
                <Flex ml="12px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
                  <Typography my={0} variant="legal" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
                    {rating.toFixed(1)}
                  </Typography>
                  <Typography fontWeight={5} ml="4px" my={0} variant="legal" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
                    {`(${ratingCount})`}
                  </Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Link>
        <Flex gridGap="16px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          {lastServiceCompletedAt && <LastServiceCompletedBlock display={['none', 'flex']} flexDirection="column" m={0} value={lastServiceCompletedAt} />}

          <ButtonLink href={bookNowButtonHref} title="Book now" onClick={event => onClickBookNow(event, profile)} target="_blank" variant="contained" width={['100px', '124px']} px={['6px', '24px']} data-sentry-element="ButtonLink" data-sentry-source-file="index.tsx">
            Book now
          </ButtonLink>
        </Flex>
      </Flex>
      <Box pb={['16px', '24px']} pl={['16px', '104px']} pr={['16px', '32px', '64px']} pt="16px" data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Flex flexDirection={['column', 'column', 'row']} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Box width={[1, '70%']} {...bio && bio.length > MAX_CHARACTERS_TO_DISPLAY_BIO && {
          height: '200px',
          overflowY: 'scroll'
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            {catchphrase && <Typography fontStyle="italic" m={0}>
                {`“${catchphrase}”`}
              </Typography>}

            {bio && <TruncatedTypography maxWidth="496px" maxCharactersToDisplay={MAX_CHARACTERS_TO_DISPLAY_BIO} mt="16px" text={bio} />}
          </Box>

          <Box pl={[0, 0, '24px']} pt={['24px', '16px']} width={[1, '30%']} data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <Grid gridTemplateColumns={['1fr 1fr', '1fr']} gridGap="8px" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
              {tags.map(({
              iconSrc,
              label
            }) => <IconAndText key={`IconAndText-${label}`} iconSrc={iconSrc} iconProps={{
              alt: label
            }} text={label} width={['max-content', '204px']} py="4px" />)}
            </Grid>
          </Box>
        </Flex>
        <Box mt={['24px', '32px']} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <ProfilesListItemSpecialties specialties={specialties} data-sentry-element="ProfilesListItemSpecialties" data-sentry-source-file="index.tsx" />
          {review && <>
              <Divider />
              <ProfileReview value={review} />
              <Divider />
            </>}
        </Box>
        {/* <LastServiceCompletedBlock
          display={['flex', 'none']}
          fontSize="12px"
          textAlign="left"
          value={lastServiceCompletedAt}
          /> */}
      </Box>
    </Box>;
};