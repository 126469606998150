import React from 'react';
import { Video, Props as VideoProps } from '../../wag-react/core-components/video';
import { Box, BoxProps } from '../../wag-react/next-components/box';
export type IframeVideoLightBoxViewProps = BoxProps & {
  videoProps?: VideoProps;
  // Convention of react-images
  data?: {
    source: string;
  };
};
export const IframeVideoLightBoxView = ({
  data: {
    source: src
  },
  videoProps: {
    iframeProps,
    ...restVideoProps
  } = {},
  ...rest
}: IframeVideoLightBoxViewProps) => <Box position="relative" pb="56.25%" // 16:9 aspect ratio
width={['320px', '720px', '1080px']} {...rest} data-sentry-element="Box" data-sentry-component="IframeVideoLightBoxView" data-sentry-source-file="index.tsx">
    <Video src={src} useIframe autoPlay controls height="auto" width="100%" iframeProps={{
    id: 'frame',
    frameBorder: '0',
    allowFullScreen: true,
    title: 'Wag! Video',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    ...iframeProps
  }} {...restVideoProps} data-sentry-element="Video" data-sentry-source-file="index.tsx" />
  </Box>;