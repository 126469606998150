type FaqEntity = {
  question: string;
  answer: string;
};
export type Params = {
  // https://developers.google.com/search/docs/data-types/faqpage
  entities: FaqEntity[];
};
export const createSchemaFaq = ({
  entities,
}: Params) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: entities.map((entity) => ({
    '@type': 'Question',
    name: entity.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: entity.answer,
    },
  })),
});
