export enum QueryStringKeys {
  PromoCode = 'promo_code',
  SignupPreferredService = 'preferred_service',
  QuestionId = 'question_id',
  SignupFlowSource = 'signup_flow_source',
  UTMMedium = 'utm_medium',
  UTMContent = 'utm_content',
  UTMTerm = 'utm_term',
  UTMSource = 'utm_source',
  UTMCampaign = 'utm_campaign',
  ZipCode = 'zip_code',
  ServiceWalkType = 'walk_type',
  Journey = 'journey',
  ArticleId = 'article_id',
  BustIframeCache = 'bust_iframe_cache',
  PetName = 'pet_name',
  PetBreed = 'pet_breed',
  PetBirthday = 'pet_birthday',
  PetWeight = 'pet_weight',
  AdCreative = 'ad_creative',
  AdGroup = 'ad_group',
  ADID = 'adid',
  GCLID = 'gclid',
  BranchLink = 'branch_link',
  WalkerUuid = 'walker_uuid',
  WalkerName = 'walker_name',
  WalkerImage = 'walker_image',
  WalkerServices='walker_services',
}

export const utmQueryStringKeys = [
  QueryStringKeys.UTMMedium,
  QueryStringKeys.UTMContent,
  QueryStringKeys.UTMSource,
  QueryStringKeys.UTMCampaign,
  QueryStringKeys.UTMTerm,
] as const;

export const vetChatQueryStringKeys = [
  QueryStringKeys.PetName,
  QueryStringKeys.PetBreed,
  QueryStringKeys.PetBirthday,
  QueryStringKeys.PetWeight,
] as const;
