/* eslint-disable react/no-danger */

// TODO: Move into wag-react so that we can use in other web repos
import React from 'react';
type Props = {
  children: unknown;
};
export const JSONLD = ({
  children
}: Props) => <script type="application/ld+json"
// Needed in order to properly render JSON object in HTML page's source code
dangerouslySetInnerHTML={{
  __html: JSON.stringify(children)
}} data-sentry-component="JSONLD" data-sentry-source-file="index.tsx" />;