import React from 'react';
import { Text } from '../../../../../../wag-react/core-components/text';
import { Typography } from '../../../../../Typography';
export type ProfileFieldTag = {
  description: string;
  isChecked: boolean;
  label: string;
  slug: string;
};
export type ProfileField = {
  id: string;
  tags: ProfileFieldTag[];
  title: string;
};
type Props = {
  specialties: ProfileField;
};
export const ProfilesListItemSpecialties = ({
  specialties
}: Props) => {
  if (!specialties) {
    return null;
  }
  const {
    tags
  } = specialties;

  // Filter for the specialties that the trainer specifically checked
  const checkedSpecialties = tags.filter(tag => tag.isChecked);
  if (checkedSpecialties.length === 0) {
    return null;
  }

  // Converts the specialties into a readable string separated by commas
  const specialtiesString = checkedSpecialties.map(specialty => specialty.label).join(', ');
  return <>
      <Typography fontWeight={5} variant="body2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        Specialties
      </Typography>

      <Typography component={Text} m={0} variant="body2" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
        {specialtiesString}
      </Typography>
    </>;
};