import React from 'react';
import styled from 'styled-components';
import ClampLines from 'react-clamp-lines';
import { Typography } from '../../../../../Typography';
import { ReviewSchema } from '../../../../../../utils/validation-schemas/walkerProfile/review';
import { formatter } from '../../../../../../utils/formatter/formatter';
import { cloudflareUrlParser } from '../../../../../../utils/formatter/parsers/cloudflareUrlParser';
import { LazyImage } from '../../../../../../wag-react/components/lazyload/LazyImage';
import { Placeholder as LazyImagePlaceholder } from '../../../../../../wag-react/components/lazyload/Placeholder';
import { Flex } from '../../../../../../wag-react/next-components/Flex';
import { Box } from '../../../../../../wag-react/next-components/box';
import { Rating } from '../../../../../../wag-react/components/Rating';
type Props = {
  value: ReviewSchema;
};
const cloudflareUrlFormatter = formatter({
  parser: cloudflareUrlParser
});
export const ProfileReview = ({
  value: {
    comment,
    name,
    picture,
    rating,
    timestamp,
    type
  }
}: Props) => {
  // TODO - Think how we can create an Image component that uses next/images (future),
  // our LazyImage, and our Image
  const resizedPicture = cloudflareUrlFormatter({
    imageUrl: picture,
    options: {
      width: 40,
      height: 40
    }
  });
  return <Flex data-sentry-element="Flex" data-sentry-component="ProfileReview" data-sentry-source-file="index.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <LazyImage src={resizedPicture} height="40px" width="40px" bg="none" overflow="unset" imageProps={{
        width: '40px',
        borderRadius: '50%'
      }} placeholder={<LazyImagePlaceholder width="40px" borderRadius="50%" />} data-sentry-element="LazyImage" data-sentry-source-file="index.tsx" />
      </Box>
      <Box ml={2} pb="16px" width={1} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <Flex justifyContent="space-between" mb="8px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <Box color="medGray" fontFamily="muli" fontWeight={5} fontSize="16px" lineHeight={1} data-sentry-element="Box" data-sentry-source-file="index.tsx">
              {name}
            </Box>
            <Box color="medGray" fontFamily="muli" lineHeight="16px" fontSize="12px" data-sentry-element="Box" data-sentry-source-file="index.tsx">
              {timestamp}
            </Box>
          </Box>
          <Flex alignItems="flex-end" flexDirection="column" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
            <Rating iconProps={{
            height: '14px',
            width: '14px'
          }} value={rating} data-sentry-element="Rating" data-sentry-source-file="index.tsx" />
            {type && <Typography variant="body2" color="wagPurple" lineHeight="16px" mt="4px" fontSize="12px">
                {type}
              </Typography>}
          </Flex>
        </Flex>
        <Box display={['block', 'none']} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <StyledClampLines id="review-text" lines={6} text={comment} moreText="Read more" lessText="Show less" data-sentry-element="StyledClampLines" data-sentry-source-file="index.tsx" />
        </Box>
        <Box display={['none', 'block']} data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <StyledClampLines id="review-text" lines={3} text={comment} moreText="Read more" lessText="Show less" data-sentry-element="StyledClampLines" data-sentry-source-file="index.tsx" />
        </Box>
      </Box>
    </Flex>;
};

/**
 * For more robust flexibility; We can mirror this library
 */
const StyledClampLines = styled(ClampLines)`
  ${({
  theme
}) => `
    .clamp-lines__button {
      border-radius: 0px;
      border: none;
      background: none;
      padding: 0;
      color: ${theme.colors.wagGreen};
      font-weight: 700;
      font-size: 16px;
      margin-top: 8px;
    }

    color: ${theme.colors.medGray};
    font-size: 14px;
  `}
`;